import React from "react";
import Link from "../components/Link";
import Copyright from "../components/Copyright";
import { Container, Box, Typography } from "@mui/material";

export default function About() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          404 Not Found
        </Typography>
        <Link to="/">← Home</Link>
        <Typography variant="h1" component="h1" gutterBottom align="center" style={{ fontFamily: "Helvetica"}}>
          ¯\_(ツ)_/¯
        </Typography>
        <Copyright />
      </Box>
    </Container>
  );
}
